@keyframes pane-in-big {
  0% {
    top: -20vh;
  }
  100% {
    top: 0;
  }
}

@keyframes pane-in-small {
  0% {
    top: -5vh;
  }
  100% {
    top: 0;
  }
}

@keyframes bob {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(2%);
  }
}

.App {
  --animation-time: 10s;
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100vw;
  height: 100vh;

  .bg {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: -1;

    div {
      position: absolute;
      height: 100%;
      width: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    &-1 {
      // background: url(./assets/bg/1-edited.png);
      background: url(./assets/bg/mountains.jpg);

      // animation: bob var(--animation-time) ease-in-out infinite alternate;
      // z-index: 0;
      // top: -35vh;
      // height: 50%;
      // filter: blur(0.15rem);
    }

    // &-2 {
    //   background: url(./assets/bg/2.png);
    //   animation: pane-in-big var(--animation-time) ease-in-out;
    //   z-index: 3;
    // }

    // &-3 {
    //   background: url(./assets/bg/3.png);
    //   animation: pane-in-small var(--animation-time) ease-in-out;
    //   z-index: 2;
    // }

    .sun {
      position: absolute;
      width: 10rem;
      height: 10rem;
      border-radius: 20rem;
      background: white;
      top: 50%;
      left: 50%;
      transform: translate(-170%, -80%);
      box-shadow: 0rem 0rem 3rem white;
      filter: blur(0.07rem);
    }
  }

  .artist {
    position: absolute;
    right: 0;
    bottom: 6.3rem;
    width: 23em;
    height: 4em;
    font-size: 0.8rem;
    padding: 1rem;
    background: rgba(255, 255, 255, 0.8);
  }
}

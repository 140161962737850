$lottie-bg: rgba(#09001e, 1);
$lottie-secondary: rgba(255, 0, 72, 0.8);

@keyframes pulse-progress {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.5;
  }
}

.tags {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 0;
  margin-top: 0.5rem;
  font-size: 1rem;

  li > * {
    background: $lottie-bg;
    color: white;
    border-radius: 3rem;
    padding: 0.3rem 0.6rem;
    line-height: 2rem;
  }

  .link > * {
    cursor: pointer;
    transition: font-size 0.2s;
    text-decoration: none;
    color: white;
    background: $lottie-secondary;

    &:hover,
    &:focus-visible {
      font-size: 1.2rem;
    }

    &:focus-visible {
      outline: 0.2rem solid black;
    }

    img {
      width: 1rem;
      height: 1rem;
      margin-left: 0.5rem;
      padding-top: 0.2rem;
      filter: brightness(0) invert(1);
    }
  }

  .completed > * {
    background: green;
  }

  .in-progress > * {
    background: purple;
    animation: pulse-progress 1s ease-in-out infinite alternate;
  }
}

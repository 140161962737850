.button-link {
  font-size: 3rem;
  width: 1.3em;
  height: 1.5em;
  color: white;
  text-decoration: none;
  border-bottom: 0.1em solid transparent;
  transition: color 0.5s, border 0.5s, background 0.5s;
  position: relative;

  &:hover {
    // color: rgb(182, 26, 182);
    color: cyan;
  }

  &:focus-visible {
    border-bottom: 0.1em solid white;
    outline: none;
  }

  & > * {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

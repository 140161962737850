.home {
  .node {
    // opacity: 0;
    // animation: fade-in 800ms ease forwards;

    &:nth-child(1) {
      animation-delay: 6s;
    }

    &:nth-child(2) {
      animation-delay: 6.2s;
    }

    &:nth-child(3) {
      animation-delay: 6.4s;
    }

    &:nth-child(4) {
      animation-delay: 6.6s;
    }
  }
}

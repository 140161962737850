.banner {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  font-size: 3rem;
  color: white;
  text-shadow: 0.07em 0.1em 0em rgba(255, 0, 72, 0.8);
  margin: 0;
  padding: 0.1em 0.7em;
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bungee&display=swap');

$lottie-bg: rgba(#09001e, 1);
$lottie-primary: rgba(0, 213, 255, 0.8);
$lottie-secondary: rgba(255, 0, 72, 0.8);

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(77, 149, 199);
  background-size: cover;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

h1,
h2,
h3 {
  font-family: 'Bungee', cursive;
  color: $lottie-secondary;
  border-bottom: 0.2rem solid $lottie-bg;
  margin: 0.8rem 0.4rem 2rem 0.4rem;
}

h2,
h3 {
  line-height: 2.5rem;
}

h4,
h5,
h6 {
  font-size: 1.25rem;
  font-family: 'Source Code Pro', monospace;
}

p {
  line-height: 1.5rem;
}

.resume p {
  line-height: 1rem;
}

.resume a {
  color: $lottie-bg;
}

section.resume {
  overflow: auto;
}

.resume section {
  width: 100%;
  max-width: 850px !important;
  max-height: 1100px !important;
  margin: 0 !important;
  margin-top: 1rem !important;
  margin-bottom: 4rem !important;
}

.print {
  .node.resume {
    box-shadow: none;
  }
}

.node.resume {
  width: 850px;
  margin: 0 !important;
  max-height: 1100px !important;
  padding-inline: 2em !important;

  section {
    margin: 0 0 0.5em 0 !important;
  }

  h2,
  h3,
  h4,
  h5 {
    margin-left: 0;
  }

  h2 {
    font-size: 2.3rem;
    margin-top: 0.7em;
    margin-bottom: 0;
    padding-bottom: 0.3em;
    color: $lottie-bg;
  }

  h3 {
    font-size: 1.3rem;
    border: none;
    color: $lottie-secondary;
    margin-bottom: 0.3em;
    margin-top: 0;
  }

  h4 {
    font-size: 0.9rem;
  }

  h5 {
    font-size: 0.8rem;
    font-weight: 100;
  }

  h4,
  h5 {
    margin: 0 0 0.4em 0;
  }

  p,
  a,
  li {
    font-size: 0.8rem;
  }

  ul {
    padding: 0.5em 0 0 1.5em;
    margin: 0 0 0.5em 0;
  }

  .header {
    margin-bottom: 1em;
  }

  .body {
    display: flex;
    gap: 2em;

    .main {
      flex: 0.65;
    }

    .side {
      flex: 0.35;
    }

    section {
      min-height: unset;
    }

    h3 {
      margin-bottom: 0;
    }
  }

  .career-obj {
    p {
      margin: 0;
      padding-left: 0.5em;
    }
  }

  .skills {
    h3 {
      margin: 0;
    }

    ul {
      padding-top: 0;
    }
  }

  .work-exp-entry {
    margin-bottom: 2em;
  }

  .entry {
    padding-left: 0.4em;
    padding-bottom: 0.1em;
  }
}

.grid-2-col {
  grid-template-columns: repeat(2, 1fr);
}

.grid-3-col {
  grid-template-columns: repeat(3, 1fr);
}

.grid-2-col,
.grid-3-col {
  display: grid;
  gap: 0.6em;
}

.grid-col-2-to-4 {
  grid-column: 2 / 4;
}

.icon-span {
  display: flex;
  align-items: center;

  img {
    width: 1em;
    height: 1em;
    margin-right: 0.5em;
  }

  p {
    margin: 0;
  }
}

.print-button {
  position: absolute;
  right: calc(max(3rem, calc(50vw - 24rem)));
  margin-top: 3rem;
  color: $lottie-bg;
  border: none;
  border-radius: 0.2rem;
  box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.5);
  height: 4em;
  width: 3em;
  cursor: pointer;

  &:hover {
    box-shadow: 0 0.125rem 0.5rem $lottie-secondary;
  }
}

@media screen and (max-width: 740px) {
  p {
    font-size: 0.8rem;
    line-height: 1.2rem;
  }

  h3 {
    font-size: 1rem;
  }

  h4,
  h5,
  h6 {
    font-size: 0.9rem;
  }

  .tags {
    span {
      font-size: 0.7rem;
    }
  }
}

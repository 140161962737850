@keyframes fade-in {
  to {
    opacity: 1;
  }
}

$lottie-bg: rgba(#09001e, 0.3);
$lottie-primary: rgba(0, 153, 255, 0.5);
$lottie-secondary: rgba(0, 153, 255, 1);

.page {
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 0;
  flex: 1 1 100%;
  display: flex;
  justify-content: center;

  section {
    margin-inline: 1rem;
    max-width: 700px;
    height: fit-content;
    min-height: 100%;

    &:last-child {
      margin-bottom: 1rem;
    }

    .node {
      margin-top: 1rem;
      margin-bottom: 4rem;
    }
  }

  /* width */
  &::-webkit-scrollbar {
    width: 1rem;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0.4rem grey;
    border-radius: 1rem;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $lottie-primary;
    border-radius: 1rem;
    box-shadow: 0 0.05rem 0.2rem $lottie-bg;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $lottie-secondary;
  }
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;

  .content {
    flex: 0.7;

    .title {
      margin-bottom: 0;
      margin-inline: 0;
    }
  }

  .lottie-container,
  button {
    flex: 0.6;
  }

  button,
  .image {
    margin-top: 1.5rem;
  }

  .image {
    flex: 0.3;
    border-radius: 1rem;
    overflow: hidden;
    pointer-events: none;
  }

  @media screen and (max-width: 740px) {
    flex-direction: column;
    gap: 0;

    .content {
      order: 1;
    }

    .image {
      max-height: 12rem;
    }

    .lottie-container {
      width: 50%;
    }
  }
}

$bg: rgba(#09001e, 1);
$primary: rgba(69, 93, 121, 0.8);
$secondary: rgba(255, 0, 72, 0.8);

@keyframes bob {
  0% {
    padding-top: 0.7rem;
    color: white;
  }
  50%,
  100% {
    padding-top: 1.1rem;
    color: rgba(243, 190, 205, 0.8);
  }
}

h2 {
  margin-bottom: 0.7rem;
}

.contact-form {
  --animation-time: 0.5s;
  width: 100%;

  label {
    font-size: 1rem;
    color: $bg;
    line-height: 2rem;
  }

  .flex {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  input,
  textarea {
    border-radius: 0.3rem;
    height: 2rem;
    border: 0.1rem solid rgba($primary, 0.5);
    background: rgba(226, 235, 243, 0.8);
    padding: 0.4rem;

    &:focus-visible {
      background: white;
    }
  }

  textarea {
    height: 5rem;
    max-width: 100%;
    min-width: 100%;
    max-height: 30rem;
    resize: none;
  }

  .half-container {
    flex-basis: calc(50% - 0.5rem);
    margin-right: 1rem;

    * {
      width: 100%;
    }
  }

  .full-container {
    flex-basis: 100%;
  }

  .half-container,
  .full-container {
    margin-bottom: 1rem;
  }

  .no-margin {
    margin: 0;
  }

  .submit {
    cursor: pointer;
    background: $bg;
    color: white;
    border: none;
    border-radius: 0.3rem;
    padding: 0.7rem;
    font-size: 1.05rem;
    margin: 0;

    &:hover:not(&:disabled),
    &:focus-visible:not(&:disabled) {
      background: $primary;
    }

    &:active:not(&:focus-visible, &:disabled) {
      background: $secondary;
    }

    &:disabled {
      opacity: 0.25;
      cursor: default;
      pointer-events: none;
    }
  }

  input:disabled,
  textarea:disabled {
    opacity: 0.75;
  }

  .invalid {
    border-color: $secondary;
  }

  .submitting,
  .submitted {
    display: flex;
    justify-content: center;
    cursor: pointer;
    background: $secondary;
    color: white;
    border: none;
    border-radius: 0.3rem;
    margin: 0;
    height: 2.6rem;

    * {
      display: inline;
      padding-top: 0.3rem;
      line-height: 0;
      animation: bob var(--animation-time) ease-in-out infinite alternate;
    }
  }

  .submitting *:nth-child(1) {
    animation: none;
  }

  .submitting *:nth-child(2) {
    animation-delay: 0s;
  }

  .submitting *:nth-child(3) {
    animation-delay: 0.2s;
  }

  .submitting *:nth-child(4) {
    animation-delay: 0.35s;
  }

  .submitting *:nth-child(2),
  .submitting *:nth-child(3),
  .submitting *:nth-child(4) {
    margin: 0;
    font-size: 1.5rem;
  }

  .submitted {
    background: limegreen;

    * {
      animation: none;
    }
  }

  @media screen and (max-width: 535px) {
    .half-container,
    .full-container {
      font-size: 0.9rem;
      flex-basis: 100%;
      margin-inline: 0.5rem;
      margin-bottom: 0.5rem;
      width: 80vw;

      * {
        font-size: 0.9rem;
      }
    }
  }
}

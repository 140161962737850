$lottie-bg: rgba(#09001e, 1);
$lottie-primary: rgba(0, 213, 255, 0.8);
$lottie-secondary: rgba(255, 0, 72, 0.8);

.video-js-custom:not(.vjs-fullscreen) {
  overflow: hidden;
  border-radius: 2em;
  box-shadow: 0.2em 0.2em 0.1em $lottie-secondary;
}

.video-js-custom {
  cursor: pointer;

  // video::after {
  //   content: url('../../../public/icons/play.svg');
  //   background: $lottie-bg;
  //   font-size: 3em;
  //   position: absolute;
  //   top: 50%;
  //   left: 50%;
  //   transform: translate(-50%, -50%);
  //   padding: 0.2em 0.5em;
  //   border-radius: 1em;
  //   box-shadow: 0.03em 0.07em $lottie-secondary;
  //   opacity: 0;
  //   transition: opacity 0.5s;
  // }

  // &:not(.vjs-paused) video::after {
  //   content: url('../../../public/icons/pause.svg');
  // }

  // video:hover {
  //   &::after {
  //     opacity: 0.7;
  //   }
  // }

  .vjs-big-play-button {
    cursor: pointer;
    transition: border-color 0.5s, box-shadow 0.5s;
    font-size: 4.5em;
    border-radius: 3em;
    width: 2em;
    outline: none;
    background: $lottie-bg;
    border: 0.1em solid $lottie-primary;
    color: $lottie-primary;
    box-shadow: 0 0.1em 0.4em $lottie-bg;
    margin: 0;
    transform: translate(-50%, -50%);
  }

  &:hover .vjs-big-play-button,
  .vjs-big-play-button:focus {
    color: $lottie-secondary;
    border-color: $lottie-secondary;
  }

  .vjs-control-bar {
    background: $lottie-bg;
    color: $lottie-primary;
    font-size: 1.5em;

    .vjs-button {
      transition: color 0.5s;
    }

    .vjs-button:hover {
      color: white;
      text-shadow: 0.1em 0.1em 0.1em $lottie-secondary;
    }
  }

  .vjs-current-time,
  .vjs-duration {
    display: block;
  }

  .vjs-volume-control {
    background: $lottie-bg;
  }

  .vjs-control {
    &:focus {
      text-shadow: none;
    }

    &:focus-visible {
      color: white;
      text-shadow: 0.1em 0.1em 0.1em $lottie-secondary;
      outline: 0.1em solid $lottie-secondary;
    }
  }
}

@media screen and (max-width: 465px) {
  .video-js.vjs-layout-x-small .vjs-progress-control,
  .video-js.vjs-layout-tiny .vjs-progress-control {
    display: flex;
  }

  .video-js .vjs-volume-panel {
    display: none;
  }
}

$lottie-bg: rgba(#09001e, 1);
$lottie-primary: rgba(0, 213, 255, 0.8);
$lottie-secondary: rgba(255, 0, 72, 0.8);

.lottie-container {
  cursor: pointer;
  transition: border-color 0.5s, box-shadow 0.5s;
  position: relative;
  border: none;
  border-radius: 1rem;
  background: none;
  outline: none;

  div {
    background: $lottie-bg;
    border-radius: 1rem;
    z-index: 2;
    pointer-events: none;
    position: relative;
  }

  &:hover,
  &:focus-visible {
    &::before {
      top: 1rem;
      left: 1rem;
    }

    &::after {
      top: -1rem;
      left: -0.5rem;
    }

    img {
      opacity: 1;
    }
  }

  &::before {
    content: '';
    position: absolute;
    width: 98%;
    height: 100%;
    top: 0.2rem;
    left: 0.3rem;
    background: none;
    z-index: 0;
    background: $lottie-primary;
    border-radius: 1rem;
    transition: top 0.5s, left 0.5s;
  }

  &::after {
    content: '';
    position: absolute;
    width: 96%;
    height: 100%;
    top: -0.35rem;
    left: 0.1rem;
    background: none;
    z-index: 1;
    background: $lottie-secondary;
    border-radius: 1rem;
    transition: top 0.5s, left 0.5s;
  }

  img {
    position: absolute;
    z-index: 4;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 3.5rem;
    height: 3rem;
    background: $lottie-secondary;
    border-radius: 1rem;
    border-bottom: 0.5rem solid $lottie-primary;
    padding: 0.5rem;
    opacity: 0;
    transition: opacity 0.5s;
  }
}

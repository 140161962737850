@keyframes expand-height-anim-in {
  0% {
    max-height: 0;
  }

  100% {
    max-height: 20rem;
  }
}

.nav-bar-container {
  // background: rgba(34, 0, 26, 0.97);
  // background: #193a51c4;
  background: rgba(255, 0, 72, 0.7);
  width: 100%;
  position: relative;

  .nav-bar-toggle-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    .nav-bar-toggle-btn {
      font-size: 1rem;
      cursor: pointer;
      background: none;
      // color: cyan;
      color: white;
      // color: rgb(212, 48, 212);
      height: 2em;
      margin: 0.2em;
      border: none;
      border-bottom: transparent solid 0.2rem;

      &:hover,
      &:focus-visible {
        color: white;
        border-bottom: white solid 0.2rem;
        outline: none;
      }
    }
  }

  .nav-bar {
    padding-top: 0.5em;
  }

  .nav-bar-constricted {
    padding-bottom: 1em;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;

    & > * {
      text-align: right;
      padding-top: 1em;
    }

    .nav-bar-button {
      font-size: 0.8rem;
    }
  }
}

.nav-bar-buttons-container:has(> .nav-bar-constricted) {
  animation: expand-height-anim-in 0.5s cubic-bezier(0.28, -0.56, 0.59, 1);
  overflow: hidden;
}

section {
  .section-title {
    text-transform: uppercase;
  }

  .entries {
    display: grid;
    grid-template-columns: 1fr;
    list-style-type: none;
    padding: 0;

    .entry {
      display: grid;
      grid-template-columns: 0.2fr 1fr auto;
      gap: 0.2rem;
      margin-bottom: 2rem;
      padding-bottom: 2rem;
      border-bottom: 0.1rem dotted gray;

      &:last-child {
        margin-bottom: 0;
        border-bottom: none;
      }

      .icon {
        position: relative;
        width: 4rem;
        height: 3rem;
        grid-column-start: 1;
        grid-row-start: 1;
        grid-row-end: 3;
        justify-self: center;
        align-self: flex-start;
        object-fit: contain;
      }

      &-title {
        margin: 0;
        grid-column-start: 2;
        grid-row-start: 1;
        text-transform: uppercase;
      }

      &-subtitle {
        grid-column-start: 2;
        grid-row-start: 2;
        margin: 0;
      }

      .date {
        grid-column-start: 3;
        grid-row-start: 1;
        justify-self: flex-end;
        margin: 0;
      }

      .tags {
        grid-column-start: 2;
        grid-column-end: 4;
        grid-row-start: 4;
      }
    }

    .entry.no-sub {
      .tags {
        grid-row-start: 2;
        margin-top: 1rem;
      }
    }

    @media screen and (max-width: 535px) {
      .entry {
        grid-template-columns: 0.2fr 1fr;

        .date {
          grid-column-start: 2;
          grid-row-start: 3;
          justify-self: flex-start;
        }
      }

      .entry.no-sub {
        .date {
          grid-column-start: 2;
          grid-row-start: 2;
          justify-self: flex-start;
        }

        .tags {
          grid-row-start: 3;
          margin-top: 1rem;
        }
      }
    }
  }
}

.icon-span {
  display: flex;
  align-items: center;

  img {
    width: 1em;
    height: 1em;
    margin-right: 0.5em;
    color: black;
  }

  p {
    margin: 0;
  }
}

.card {
  background: white;
  padding: 1rem;
  box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.5);
  border-radius: 0.875rem;
}

@media screen and (max-width: 535px) {
  .card {
    padding: 0.5rem;
  }
}

footer {
  background: rgba(0, 153, 255, 0.3);
  display: flex;
  flex: 8rem;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 1.1rem;
  padding-inline: 1em;

  .button-link {
    font-size: 2.25rem;
  }

  @media screen and (max-width: 740px) {
    max-height: 3rem;
    font-size: 0.8rem;

    .button-link {
      font-size: 1.5rem;
    }
  }

  .contact-info-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: white;
  }

  .button-links {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1em;
  }
}

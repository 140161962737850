header {
  z-index: 1;
  // background: rgba(77, 149, 199, 0.8);
  background: rgba(0, 213, 255, 0.3);

  @media screen and (max-height: 740px) {
    .banner {
      font-size: 1.8rem;
    }
  }

  @media screen and (max-width: 465px) {
    .banner {
      font-size: 1.2rem;
    }
  }
}

.nav-bar-button {
  cursor: pointer;
  background: transparent;
  color: rgba(255, 255, 255, 0.8);
  font-size: 1.2rem;
  font-weight: 500;
  width: 7rem;
  border: transparent solid 0.2rem;
  transition: color 0.5s, border 0.5s;
  margin-inline: 0.4em;

  &:hover,
  &:focus-visible {
    color: white;
    border-bottom: white solid 0.2rem;
    outline: none;
    font-weight: 700;
  }
}

.active {
  // color: rgb(212, 48, 212);
  // color: cyan;
  color: white;
  font-weight: 700;

  &:hover,
  &:focus-visible {
    // color: rgb(255, 138, 255);
    // color: rgb(160, 243, 243);
  }
}
